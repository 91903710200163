import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/nav";
import PortalDrawer from "../components/portal-drawer";
import Footer from "../components/footer";
import Section from "../components/section";
import Items from "../components/items";
import Head from "../components/head";
import Border from "../components/border";

const Accueil = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const openNav = useCallback(() => {
    setNavOpen(true);
  }, []);

  const closeNav = useCallback(() => {
    setNavOpen(false);
  }, []);

  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);

  document.body.style.overflow = 'hidden';

  setTimeout(() => {
    setShow(false);
    document.body.style.overflow = 'auto';
  }, 1000);

  setTimeout(() => {
    setShow2(false);
  }, 1500);

  function handleSearch(event) {
    event.preventDefault(); // prevent the default form submission behavior

    const input = document.getElementById("search-input");
    const query = input.value.toLowerCase();

    // loop through all the items we want to search (in this case, just the trend items)
    const trendItems = document.querySelectorAll(".trend-item");
    trendItems.forEach((item) => {
      const text = item.textContent.toLowerCase();
      if (text.includes(query)) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
  }

  return (
    <>
      <div className="bg-white w-full flex flex-col items-start justify-start">

        <div className={"bg-white z-50 w-full h-screen grid place-items-center fixed top-0 left-0" + (show2 ? "" : " w-0 h-0")}>
          <img className={"w-[50%] transition duration-500 ease-in-out" + (show ? "" : " opacity-0")} src="../logo.svg" />
        </div>

        <header className="w-full h-screen lg:h-[1246px] text-left text-white font-menu-main1">

          <img
            className="w-full h-screen lg:h-[1246px] object-cover rounded-bl-3xxl"
            src="../rugby.png"
          />

          <div className="absolute w-[90%] top-[35%] md:top-[40%] lg:top-[70%] h-[40%] left-[10%]">

            <div className="relative leading-[50px] text-2xl lg:leading-[125px] lg:text-4xl uppercase w-[70%] font-bold font-sans drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              soyez prêts !
            </div>

            <div className="relative leading-[50px] text-2xl lg:leading-[125px] lg:text-3xl uppercase w-[70%] font-bold font-sans drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              weekend du 23/24 mars
            </div>

            <Link
              className="absolute top-[160%] cursor-pointer h-[11%] w-[180px] [text-decoration:none] rounded-2xxs bg-brown flex flex-row py-[9px] px-[2%] items-center justify-start gap-[10%] text-15xs text-[inherit] invisible lg:visible transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110"
              to="/participer">
              <p className="relative w-[80%] ">En savoir plus</p>
              <img
                className="relative w-[7%] items-center justify-center"
                src="../vector57.svg" />
            </Link>

            <div className="hidden lg:visible absolute w-[20%] top-[0%]  left-[70%] lg:flex flex-col py-[5px] box-border items-start justify-start gap-[30px] text-17xs font-source-sans-pro shrink-0">

              <Items
                title1="Partenaire"
                title2="Devenez partenaire du Challenge"
                description="Découvrez comment devenir partenaire de l’évenement"
                bg="bg-[#003F5C]"
                text="text-[#FFB2DB]"
                link="/partenaires"
              />

              <Items
                title1="Engagement"
                title2="Engagement"
                description="Découvrez notre engagement pour un Challenge responsable"
                bg="bg-[#004F45]"
                text="text-[#3EDE8E]"
                link="/engagements"
              />

            </div>

          </div>

          <Head nav={openNav} name={"Accueil"} />

        </header>

        <Border />

        <Section
          text="Le Challenge est une compétition sportive étudiante qui
          regroupe 23 sports différents. Que vous soyez un athlète
          confirmé ou que vous cherchiez simplement à vous divertir en
          pratiquant un sport, vous trouverez votre bonheur parmi nos
          nombreuses disciplines. Nous proposons des sports collectifs
          comme le football masculin et féminin, le basket masculin et
          féminin, le padel, le rugby masculin et féminin, le volley-ball
          masculin et féminin, le spikeball, le handball masculin et féminin et l'ultimate, ainsi que des sports individuels comme
          l'athlétisme, le badminton, l'escalade, l'escrime, le judo, la
          natation/water-polo, le street-workout, le surf, la pétanque, le trail, le
          tennis et le tennis de table. Nous sommes
          convaincus que chacun trouvera une discipline qui lui
          convient parmi cette large gamme de sports proposés.
          Rejoignez-nous pour découvrir ou redécouvrir le plaisir de
          pratiquer un sport et de se dépasser !"
          title="Sport"
          imageRow="../waterpolo.png"
          imageCol="../waterpolo.png"
          color="text-brown"
          height = "h-[100vh] lg:h-[107vh]"/>

        <Border />

        <Section
          text="Le Challenge Centrale Lyon est fier de porter le précieux
          label écologique délivré par le comité Olympique. Cette
          reconnaissance témoigne de notre engagement à respecter les
          valeurs du développement durable, tant sur le plan
          écologique que sur le plan social. Nous sommes convaincus
          que le sport peut être un vecteur de changement positif pour
          notre société et notre planète, et nous mettons tout en
          œuvre pour organiser une compétition étudiante respectueuse
          de l'environnement et de l'éthique. Rejoignez-nous pour
          célébrer les valeurs du sport et du développement durable !"
          title="Engagement"
          imageRow="../basket.png"
          imageCol="../basket.png"
          color="text-[#003F5C]"
          height = "h-[80vh] lg:h-[80vh]" />

        <Border />

        <Section
          text="Vous êtes étudiant et vous souhaitez participer au Challenge
          2024 ? Si votre école figure sur notre liste des écoles
          invitées, vous pouvez vous inscrire auprès des bureaux des
          sports (BDS) de votre école. Nous proposons une seule
          catégorie de participation, qui regroupe les meilleures
          équipes de chaque discipline de chaque école. Pour vous
          inscrire, il vous suffit de vous rendre auprès des bureaux
          des sports de votre école et de remplir le formulaire
          d'inscription. Les frais d'inscription couvrent les coûts de
          l'organisation de l'événement. Nous vous invitons également
          à prendre connaissance de notre charte de bonne conduite,
          qui rappelle les règles de fair-play et de respect mutuel
          qui régissent le Challenge. Si votre école n'est pas sur
          notre liste des écoles invitées, nous vous invitons à
          solisciter votre BDS. Nous espérons que vous serez parmi les
          participants l'année prochaine et que vous prendrez part à
          cette expérience sportive unique et inoubliable !"
          title="Participant"
          imageRow="../feux_artifice.png"
          imageCol="../feux_artifice.png"
          color="text-[teal]"
          height = "h-[100vh] lg:h-[107vh]" />

        <Border />

        <Footer />

      </div>

      {isNavOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeNav}
        >
          <Nav onClose={closeNav} />
        </PortalDrawer>
      )}

    </>
  );
};

export default Accueil;
