import { useCallback } from "react";
import Media from "./media";

const SocialNetwork = () => {

  const socialLinks = [
    {
      link: "../social/youtube.svg",
      click: useCallback(() => { window.open("https://www.youtube.com/user/ChallengeECLyon"); }, []),
    },
    {
      link: "../social/twitter.svg",
      click: useCallback(() => { window.open("https://twitter.com/challenge_ecl"); }, []),
    },
    {
      link: "../social/linkedin.svg",
      click: useCallback(() => { window.open("https://www.linkedin.com/company/challenge-centrale-lyon/"); }, []),
    },
    {
      link: "../social/facebook.svg",
      click: useCallback(() => { window.open("https://www.facebook.com/ChallengeCentraleLyon"); }, []),
    },
    {
      link: "../social/instagram.svg",
      click: useCallback(() => { window.open("https://www.instagram.com/challengecentralelyon/"); }, []),
    }
  ];

  return (
    <footer className="bg-gray-500 flex flex-row items-center justify-evenly w-screen h-[20vh]">
      {socialLinks.map((link, index) => (
        <Media key={index} link={link.link} click={link.click} />
      ))}
    </footer>
  );
};

export default SocialNetwork;
