import { useState, useCallback } from "react";
import Nav from "../components/nav";
import PortalDrawer from "../components/portal-drawer";
import Head from "../components/head";
import Footer from "../components/footer";
import Border from "../components/border";

const DevenirPartenaire = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const openNav = useCallback(() => {
    setNavOpen(true);
  }, []);

  const closeNav = useCallback(() => {
    setNavOpen(false);
  }, []);

  return (
    <>
      <div className="bg-softBlack w-screen flex flex-col items-start justify-start">

        <div className="w-screen h-[50vh]">
          <Head nav={openNav} name={"Devenir Partenaire"} />
        </div>

        <Border />

        <div className="w-screen h-fit grid place-items-center bg-gray-300">
          <iframe src="../pdf/plaquette.pdf" className="w-[99%] h-screen" />
        </div>

        <Border />

        <Footer />

      </div>
      {isNavOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeNav}
        >
          <Nav onClose={closeNav} />
        </PortalDrawer>
      )}
    </>
  );
};

export default DevenirPartenaire;
