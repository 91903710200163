import { useState, useCallback, useLayoutEffect } from "react";
import Nav from "../components/nav";
import PortalDrawer from "../components/portal-drawer";
import Head from "../components/head";
import Footer from "../components/footer";
import TextPlaceHolder from "../components/textPlaceHolder";
import Border from "../components/border";

const PartenaireItem = ({ image, company, text }) => {

  return (
    <div className="flex flex-col justify-between items-start">
      <div className="flex flex-row justify-start items-start w-[100%] py-[3%]">
        <img className="w-[150px] mr-[5vh]" src={image} />
        <div className="h-full w-[85vh]">
          <div className="text-black h-[15vh] w-[100%] flex items-start justify-start text-[20px] font-bold">
            {company}
          </div>
          <div className="text-black h-fit w-[100%] flex items-center justify-start">
            {text}
          </div>
        </div>
      </div>
      <div className="w-[100%] h-[2px] bg-softBlack" />
    </div>
  );
};

const Partenaires = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const openNav = useCallback(() => {
    setNavOpen(true);
  }, []);

  const closeNav = useCallback(() => {
    setNavOpen(false);
  }, []);

  useLayoutEffect(() => {

    const handleScroll = () => {
      const positions = partners.map((_categories, index) => {
        const element = document.getElementById(index + "E");
        const text = document.getElementById(index + "T");
        const rectE = element.getBoundingClientRect();
        const rectT = text.getBoundingClientRect();
        const top = rectT.top - rectE.top
        return top;
      });
      for (let i = 0; i < positions.length; i++) {
        if (positions[i] >= 0) {
          setActiveIndex(i);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const partners = [
    [
      { key: "PWC", image: "../sponsor/logo_pwc.png", company: "PWC", text: "En France et au Maghreb, PwC développe des missions de conseil, d’audit et d’expertise juridique et fiscale. Et ce, pour des organisations de toutes tailles et de tous secteurs d’activité. Parce que votre terrain de jeu est sans limites, nous vous offrons des missions ambitieuses, une organisation flexible, un environnement de travail unique et des opportunités de développement illimitées. En France, PwC est certifié Top Employer. Nous sommes également partenaire officiel des Jeux Olympiques et Paralympiques de Paris 2024, en accompagnant son comité d’organisation dans la tenue du plus grand événement mondial : l’opportunité de contribuer à des projets complexes et porteurs de sens. Faites partie des 8 000 nouveaux talents qui rejoindront nos équipes France et Maghreb d’ici 2025. Rejoignez vous aussi La Nouvelle Équation." },
      { key: "EDF", image: "../sponsor/logo_edf.png", company: "EDF", text: "EDF, ou Électricité de France, est un leader mondial de l'énergie. Présent dans plus de 30 pays, EDF est un acteur majeur de la transition énergétique, offrant des solutions innovantes et durables pour répondre aux besoins énergétiques de demain. Au cœur de notre mission se trouve l'engagement envers la fourniture d'une énergie propre, sûre et abordable pour tous. Nous sommes pionniers dans le développement des énergies renouvelables, investissant dans l'éolien, le solaire, l'hydroélectricité et d'autres sources d'énergie verte pour créer un avenir énergétique plus durable. Chez EDF, nous croyons en l'importance de l'innovation et de la collaboration pour relever les défis énergétiques mondiaux. Nous offrons un environnement de travail stimulant où chaque employé a la possibilité de contribuer à des projets d'envergure internationale. En tant qu'entreprise engagée envers le développement durable, nous sommes fiers de notre contribution à la lutte contre le changement climatique et à la promotion d'une économie bas carbone. Nous travaillons en partenariat avec les gouvernements, les entreprises et les communautés pour façonner un avenir énergétique plus propre et plus prospère pour tous. Rejoignez-nous chez EDF et faites partie de notre mission pour un avenir énergétique durable."}
    ],
    [
      { key: "CVEC", image: "../sponsor/logo_cvec.png", company: "CVEC", text: "La qualité de la vie étudiante et de campus est un facteur de réussite des étudiants et d’attractivité des établissements. C’est pourquoi cette contribution est destinée à favoriser l’accueil et l’accompagnement social, sanitaire, culturel et sportif des étudiants et à conforter les actions de prévention et d’éducation à la santé réalisées à leur intention, en abondant les moyens déjà alloués par les établissements." },
      { key: "CENTRALE LYON", image: "../sponsor/logo_ecl.png", company: "CENTRALE LYON", text: "Centrale Lyon est une grande école d'ingénieurs française, spécialisée dans les sciences de l'ingénieur et les technologies de pointe. Elle forme des ingénieurs de haut niveau capables de relever les défis de demain." },
      { key: "METROPOLE DE LYON", image: "../sponsor/logo_Metropole_Lyon.png", company: "METROPOLE DE LYON", text: "La Métropole de Lyon est une collectivité territoriale française regroupant 59 communes. Elle est chargée d'assurer le développement économique, social et culturel de son territoire, ainsi que l'aménagement urbain et la protection de l'environnement." },
      { key: "REGION", image: "../sponsor/logo_region.png", company: "REGION AUVERGNE-RHONE-ALPES", text:"La Région Auvergne-Rhône-Alpes, regroupant plusieurs départements, est une institution territoriale engagée dans le développement économique, social et culturel de son territoire. Son action vise à favoriser l'épanouissement de ses habitants, à soutenir les initiatives locales et à promouvoir l'attractivité régionale."},
      { key: "ECULLY", image: "../sponsor/logo_ecully.jpg", company: "ECULLY", text:"La ville d'Ecully, située dans la métropole de Lyon, est une commune dynamique et attractive. Elle offre un cadre de vie agréable, alliant modernité et tradition. Engagée dans le développement local, Ecully s'attache à répondre aux besoins de ses habitants et à promouvoir son patrimoine naturel et culturel."}
    ],
    [
      { key: "PASQUIER", image: "../sponsor/logo_pasquier.png", company: "PASQUIER", text: "Dégustez des pâtisseries et des viennoiseries de qualité artisanale avec les produits Pasquier. Fabriqués avec des ingrédients sélectionnés avec soin, leurs produits sont parfaits pour le petit déjeuner ou le goûter. Essayez-les dès aujourd'hui et soutenez une entreprise française traditionnelle engagée dans la production durable." },
      { key: "ACTION CONTRE LA FAIM", image: "../sponsor/logo_action_faim.png", company: "ACTION CONTRE LA FAIM", text: "Action contre la Faim – lutte contre la faim dans le monde. Les conflits, les dérèglements climatiques, la pauvreté, les inégalités d’accès à l’eau, aux soins, sont autant de causes de la malnutrition. Notre mission est de sauver des vies en éliminant la faim par la prévention, la détection et le traitement de la sous-nutrition, en particulier pendant et après les situations d’urgence liées aux conflits et aux catastrophes naturelles." },
      { key: "ANAE", image: "../sponsor/logo_anae.png", company: "ANAE", text: "ANAE Vacances, une entreprise dédiée aux séjours de vacances, offre des expériences uniques et enrichissantes pour les jeunes. Avec des programmes variés et adaptés à différents âges, ANAE Vacances propose des aventures inoubliables, favorisant le développement personnel et les rencontres interculturelles."},
      { key: "ANDROS", image: "../sponsor/logo_andros.png", company: "ANDROS SPORT", text: "Fondée sur la passion pour le bien-être et la performance, Andros Sport est une entreprise dédiée à l'excellence. Depuis nos débuts, nous nous engageons à proposer des produits innovants pour les sportifs et les amateurs de vie saine. Nous croyons en l'importance d'une alimentation équilibrée pour atteindre ses objectifs de santé et de performance sportive. Nos produits, élaborés avec des ingrédients de qualité supérieure, sont conçus pour répondre aux besoins nutritionnels spécifiques des athlètes, tout en offrant une expérience gustative incomparable. Rejoignez-nous dans notre quête de dépassement de soi et de bien-être, avec Andros Sport, votre partenaire pour une vie active et épanouissante."},
      { key: "CITY SURF PARK", image: "../sponsor/logo_city_surf.png", company: "CITY SURF PARK", text: "City Surf Park, l'oasis urbaine des passionnés de surf. Découvrez un univers où les vagues parfaites rencontrent l'excitation citadine. Avec nos installations de classe mondiale et notre équipe d'instructeurs expérimentés, nous vous offrons bien plus qu'une simple session de surf. Plongez dans une atmosphère conviviale et découvrez une communauté de passionnés où le partage et l'aventure sont au rendez-vous. Rejoignez-nous pour une expérience de surf inoubliable au cœur de la ville, où chaque vague vous promet des moments de pur plaisir et de découverte."}
    ],
  ];

  const type = ["Officiel", "Institutionnel", "Logistique"]

  return (
    <>
      <div className="bg-gray-100 w-screen flex flex-col items-start justify-start text-white font-menu-main1">

        <div className="w-screen h-[50vh] bg-softBlack">
          <Head nav={openNav} name={"Partenaires"} />
        </div>

        <div className="h-screen text-white font-menu-main1">
          <img
            className="w-screen h-screen object-cover rounded-bl-3xxl"
            src="../clapping.png"
          />

          <div className="absolute top-[50vh] left-0 w-screen h-screen flex items-center justify-center">
            <div className="text-center leading-[50px] text-2xl lg:leading-[200px] lg:text-4xl uppercase font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Des partenaires engagés
            </div>
          </div>

        </div>

        <Border />

        <TextPlaceHolder
          text="Les partenaires sont des acteurs clés de la réalisation et du
      financement du Challenge 2024. Leur contribution peut être financière ou technique, à travers
      la fourniture de produits et de services : ils collaborent
      ainsi à l’organisation du Challenge 2024." />

        <Border />

        <div className="flex flex-col md:flex-row justify-evenly items-start w-screen py-[10%] h-fit">

          <div className="md:sticky top-[30%] w-[100%] md:w-[40%] h-[50vh] flex flex-row items-start justify-start">

            <img className="w-[50%] h-[50%]" src="../logo.svg" />

            <div className="w-[40%] h-full flex flex-col item-center items-start justify-evenly">
              {type.map((type, index) => (
                <div className="flex flex-row items-center justify-between w-full h-full">
                  <div id={index + "T"} className={"text-softBlack text-[20px]" + (activeIndex == index ? " md:text-[30px] md:font-bold" : " text-[20px]")}>{type}</div>
                  <img className={(activeIndex == index ? " md:w-[20px]" : " md:w-[10px]")} src="../stripe_right.svg" alt="" />
                </div>

              ))}
            </div>

          </div>

          <div className="w-[100%] md:w-[40%] flex flex-col">

            <div className="w-[100%] h-[2px] bg-softBlack" />
            Ò
            {partners.map((categories, index) => (
              <div id={index + "E"} className="snap-mandatory snap-y ">
                {categories.map(({ company, image, text }) => (
                  <div className="snap-center">
                    <PartenaireItem key={company} image={image} company={company} text={text} />
                  </div>
                ))}
              </div>
            ))}

          </div>
        </div>

        <Border />

        <TextPlaceHolder
          text="Le Challenge est fier de compter sur le soutien de partenaires
            engagés qui nous accompagnent dans notre événement de l'année
            2024. Leur engagement est précieux pour nous et nous permet de
            rayonner nos valeurs sportives. Nous sommes convaincus que le
            sport est un vecteur de valeurs positives et nous souhaitons
            partager ces valeurs avec nos partenaires. Grâce à leur soutien,
            nous sommes en mesure de proposer un événement de qualité qui
            réunit des étudiants de tous horizons et leur offre l'opportunité
            de se dépasser et de s'épanouir grâce au sport. Nous sommes
            reconnaissants envers nos partenaires pour leur implication et
            leur engagement à nos côtés, et nous espérons que notre
            collaboration continuera à se développer dans les années à venir." />

        <Border />

        <Footer />

      </div>

      {
        isNavOpen && (
          <PortalDrawer
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Left"
            onOutsideClick={closeNav}
          >
            <Nav onClose={closeNav} />
          </PortalDrawer>
        )
      }

    </>
  );
};

export default Partenaires;
