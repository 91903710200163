
const SellItems = ({ article, prix, text, img , size}) => {
  return (
    <div className="w-screen h-screen flex flex-row items-start justify-start">
      <div className="bg-white grid place-items-center w-1/2 h-full">
        <img className={"w-["+size+"]"} src={img} alt="Logo" />
      </div>
      <div className="w-1/2 h-full flex flex-col justify-start items-center font-source-sans-pro">
        <div className="text-white w-4/5 h-4/5 flex flex-col items-center justify-start">
          <Title>{article}</Title>
          <Subtitle>{prix}</Subtitle>
          <Subtitle>Description</Subtitle>
          <Text>{text}</Text>
        </div>
        <div className="flex justify-evenly items-center w-full h-1/5 text-softBlack">
          { (img != "../gourde.png")  && 
          (<>
          <SizeButton>S</SizeButton>
          <SizeButton>M</SizeButton>
          <SizeButton>L</SizeButton>
          </>
          )
          }
        </div>
      </div>
    </div>
  );
};

const Title = ({ children }) => {
  return (
    <div className="w-full h-1/6 text-2xl flex items-center">
      {children}
    </div>
  );
};

const Subtitle = ({ children }) => {
  return (
    <div className="w-full h-1/10 flex items-center text-[30px]">
      {children}
    </div>
  );
};

const Text = ({ children }) => {
  return (
    <div className="w-[full] h-[65%] grid place-items-center text-left text-[15px] md:text-[20px]">
      {children}
    </div>
  );
};

const SizeButton = ({ children }) => {
  return (
    <div className="rounded-4xxl bg-white w-[5vw] h-[5vw] grid place-items-center text-[25px] md:text-[30px] lg:text-xl">
      {children}
    </div>
  );
};

export default SellItems;
