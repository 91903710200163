import { useState, useCallback } from "react";
import Nav from "../components/nav";
import PortalDrawer from "../components/portal-drawer";
import Head from "../components/head";
import Footer from "../components/footer";
import Section from "../components/section";
import Border from "../components/border";
import TextPlaceHolder from "../components/textPlaceHolder";

const Engagements = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const openNav = useCallback(() => {
    setNavOpen(true);
  }, []);

  const closeNav = useCallback(() => {
    setNavOpen(false);
  }, []);

  return (
    <>
      <div className="bg-softBlack w-screen flex flex-col items-start justify-start text-gray-100 font-source-sans-pro">

        <div className="w-full h-[100vh]">

          <img
            className="w-screen h-[100vh] object-cover object-right"
            src="../engagement.png"
          />

          <Head nav={openNav} name={"Engagements"} />

          <div className="absolute w-[90%] top-[60%] h-[40%] left-[10%]">
            <div className="text-left leading-[50px] text-3xl md:text-3xl md:leading-[80px] lg:leading-[100px] lg:text-4xl uppercase w-[75%] font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Le challenge s’engage
            </div>
          </div>

          <img
            className="absolute bottom-[0px] right-[0px] w-[200px] h-[80px] md:w-[400px] md:h-[160px] lg:w-[500px] lg:h-[200px]"
            src="../agenda_logo.png"
          />
        </div>

        <TextPlaceHolder
          text="Le Challenge Centrale Lyon est fier de porter le précieux label
            écologique délivré par le comité Olympique. Cette reconnaissance
            témoigne de notre engagement à respecter les valeurs du
            développement durable, tant sur le plan écologique que sur le plan
            social. Nous sommes convaincus que le sport peut être un vecteur de
            changement positif pour notre société et notre planète, et nous
            mettons tout en œuvre pour organiser une compétition étudiante
            respectueuse de l'environnement et de l'éthique. Rejoignez-nous pour
            célébrer les valeurs du sport et du développement durable !" />

        <Section
          text="Le handisport est une pratique sportive accessible à tous,
            quels que soient les handicaps ou les limitations physiques.
            Au Challenge Centrale Lyon, nous croyons fermement en
            l'importance de la participation de tous et de l'inclusion
            dans le sport. Nous sommes convaincus que le sport peut être
            un vecteur de développement personnel et de bien-être pour
            tous, quelle que soit la condition physique de chacun. C'est
            pourquoi nous organisons des représentations de handisport et
            des initiations aux pratiques handisport avec des
            professionnels qualifiés. Nous voulons offrir à tous
            l'opportunité de découvrir et de s'épanouir dans le sport,
            quelle que soit leur condition physique. Nous croyons que le
            handisport est un moyen de briser les barrières et de célébrer
            la diversité et l'inclusion dans le sport. Rejoignez-nous pour
            partager cette passion et cette conviction !"
          title="Handisport"
          imageRow="../handicape.png"
          imageCol="../handicap2.jpeg"
          color="text-brown"
          height = "h-[100vh] lg:h-[100vh]"  />

        <Border />

        <Section
          text="Le Mini-Challenge est une journée sportive destinée aux jeunes
          de centres sociaux. Nous croyons que le sport est un vecteur
          de développement personnel et de bien-être, et nous voulons
          offrir à ces jeunes l'opportunité de s'épanouir et de
          s'engager dans une activité physique. Nous organisons cette
          journée en collaboration avec des professionnels et des
          bénévoles passionnés, pour offrir une expérience ludique et
          motivante. Nous proposons une variété d'activités sportives
          adaptées aux différents niveaux et aux différents centres
          sociaux, afin que chacun puisse trouver une discipline qui lui
          correspond. Nous croyons que le Mini-Challenge est un moyen de
          promouvoir l'importance de l'engagement dans le sport et de la
          pratique physique pour la santé et le bien-être de tous,
          quelle que soit la condition socio-économique de chacun.
          Rejoignez-nous pour partager cette passion et cette conviction
          !"
          title="Mini-Challenge"
          imageRow="../social.png"
          imageCol="../social2.jpeg"
          color="text-orange"
          height = "h-[100vh] lg:h-[100vh]" />

        <Border />

        <Section
          text="Le tri sélectif est une pratique essentielle pour préserver
          l'environnement et réduire notre impact sur la planète. Au
          Challenge Centrale Lyon, nous croyons fermement en
          l'importance de l'engagement environnemental et nous mettons
          tout en œuvre pour organiser une compétition étudiante
          respectueuse de l'environnement. C'est pourquoi nous
          encourageons les participants à trier leurs déchets de manière
          sélective et nous supervisons le tri avec l'aide de notre
          équipe de bénévoles, la Green Army. En sélectionnant les
          matières qui peuvent être recyclées, nous pouvons contribuer à
          réduire les déchets et à préserver les ressources naturelles.
          Nous mettons à disposition des poubelles de tri sélectif dans
          toutes les zones de l'événement et nous sensibilisons les
          participants aux bons gestes de tri. Nous croyons que le tri
          sélectif est un moyen de montrer notre responsabilité
          collective et de sensibiliser chacun à son impact sur
          l'environnement. Rejoignez-nous pour célébrer l'importance de
          l'engagement environnemental et de la préservation de notre
          planète !"
          title="Tri Selectif"
          imageRow="../ecolo.png"
          imageCol="../ecolo2.jpeg"
          color="text-teal"
          height = "h-[100vh] lg:h-[107vh]" />

        <Border />

        <Section
          text="Au Challenge Centrale Lyon, nous croyons que c'est aux
          actions, et non aux mots, que nous pouvons mesurer notre
          engagement à protéger l'environnement. C'est pourquoi nous
          avons pris l'engagement ambitieux de ne pas utiliser de
          bouteilles en plastique pendant notre événement. Nous sommes
          conscients que l'utilisation massive de bouteilles en
          plastique est l'une des principales causes de pollution et de
          dégradation de l'environnement, et nous voulons contribuer à
          lutter contre cette problématique en proposant à nos
          participants des alternatives écologiques, comme des fontaines
          d'eau ou des gourdes réutilisables. Nous espérons ainsi
          atteindre notre objectif zéro bouteille et contribuer ainsi
          à la préservation de notre planète. Rejoignez-nous pour
          célébrer l'importance de l'engagement environnemental et de la
          préservation de notre planète. Nous sommes impatients de vous
          accueillir et de partager notre passion pour la protection de
          l'environnement avec vous !"
          title="Zero Bouteille"
          imageRow="../bouteille.png"
          imageCol="../bouteille2.jpeg"
          color="text-gray-600"
          height = "h-[100vh] lg:h-[107vh]" />

        <Border />

        <Footer />

      </div>
      {isNavOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeNav}
        >
          <Nav onClose={closeNav} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Engagements;
