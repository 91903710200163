const BorderV2 = () => {
    return (
        <>
            <div className="bg-gray-500 w-screen h-[20px]" />
        </>
    );
};

export default BorderV2;

//shadow-[5px_1px_4px_rgba(0,_0,_0,_0.15)_inset]