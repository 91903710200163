const BoxSection = ({ text, background, color, id, children, h }) => {
  return (
    <div id={id} className={`w-screen h-screen flex flex-col rounded-tr-[150px] rounded-bl-[150px] md:rounded-tr-3xxl md:rounded-bl-3xxl ${background} text-left ${h}`}>
      <div className={`leading-[40px] md:leading-[60px] lg:leading-[100px] px-[10%] pt-[5%] uppercase flex items-center justify-start w-[80%] h-[30vh] text-2xl md:text-3xl lg:text-4xl ${color}`}>
        {text}
      </div>
      {children}
    </div>
  );
};

export default BoxSection;
