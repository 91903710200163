
const SectionTitle = ({ title }) => {
    return (
        <div className={"w-[80%] h-[20vh] flex flex-row justify-around items-center"}>
            <div className="w-full bg-gray-700 h-px" />
            <div className="w-full leading-[45px] font-black bg-white flex flex-row justify-center">{title}</div>
            <div className="w-full bg-gray-700 h-px" />
        </div>
    );
}

const ImageGrid = ({ images, size }) => {
    return (
        <div className="row-auto items-center flex flex-col md:flex-row w-screen md:min-width-1/2 justify-evenly gap-[50px]">
            {images.map(image => (
                <img key={image.src} className={" " + size + " "} src={image.src} alt={"Partern logo"} />
            ))}
        </div>
    )
}

const Logo = () => {

    const partners = {
        pwc: { src: "../sponsor/logo_pwc.png" },
        cvec: { src: "../sponsor/logo_cvec.png" },
        ecl: { src: "../sponsor/logo_ecl.png" },
        lyon: { src: "../sponsor/logo_Metropole_Lyon.png" },
        contrefaim: { src: "../sponsor/logo_action_faim.png" },
        pasquier: { src: "../sponsor/logo_pasquier.png" },
        edf: { src: "../sponsor/logo_edf.png" },
        ecully: {src: "../sponsor/logo_ecully.jpg" },
        anae: {src: "../sponsor/logo_anae.png" },
        andros: {src: "../sponsor/logo_andros.png" },
        city_surf: {src: "../sponsor/logo_city_surf.png" },
        region: {src: "../sponsor/logo_region.png"}
    };

    const partnerTypes = [
        { title: "Partenaires Officiels", size: "w-[25%] lg:w-[12%]", images: [partners["pwc"], partners["edf"]] },
        { title: "Partenaires Institutionnels", size: "w-[25%] lg:w-[10%]", images: [partners["cvec"], partners["ecl"], partners["lyon"], partners["region"], partners["ecully"]] },
        { title: "Partenaires Logistiques", size: "w-[20%] lg:w-[10%]", images: [partners["pasquier"], partners["contrefaim"], partners["anae"], partners["andros"], partners["city_surf"]] },
    ];

    return (
        <section className="self-stretch rounded-tr-[150px] rounded-bl-[150px] md:rounded-tr-2xxl md:rounded-bl-2xxl bg-white py-[100px] box-border gap-[30px] w-screen h-fit flex flex-col items-center justify-start text-center text-4xs text-gray-700 font-menu-main1">
            {partnerTypes.map(({ title, images, size }) => (
                <>
                    <SectionTitle title={title} />
                    <ImageGrid images={images} size={size}/>
                </>
            ))}
        </section>
    );
};

export default Logo;