import Head from "../components/head";
import { useCallback, useState } from "react";
import Footer from "../components/footer";
import PortalDrawer from "../components/portal-drawer";
import Border from "../components/border";
import Nav from "../components/nav";

const Podium = () => {

    const [isNavOpen, setNavOpen] = useState(false);
    const handleNavClick = useCallback(() => {
        setNavOpen(!isNavOpen);
    }, [isNavOpen]);

    fetch('https://challenge-centrale-lyon.fr/api')
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.log(error));

    const classement = [
        { nom: "Centrale Lyon", premier: 4, deuxieme: 8, troisieme: 3 },
        { nom: "Centrale supélec", premier: 3, deuxieme: 2, troisieme: 6 },
        { nom: "Mines d'Alès", premier: 3, deuxieme: 1, troisieme: 1 },
        { nom: "EPFL", premier: 2, deuxieme: 5, troisieme: 0 }
    ];

    classement.sort((a, b) => { return b.point - a.point });

    const columns = ["N°", "Ecole", "1er", "2ème", "3ème", "Point"];

    return (
        <div className="bg-softBlack w-screen flex flex-col items-center justify-start relative">

            <div className="w-screen h-[50vh] bg-softBlack">
                <Head nav={handleNavClick} name="Boutique" />
            </div>

            <div className="w-[90vw]  bg-white ">
                <div className="w-full h-[10vh] text-center text-[60px] bg-[#08319f] flex flex-row items-center justify-center">
                    <div className="text-white">Résultat &nbsp;</div>
                    <div className="text-[#fb8315]">Challenge Centrale Lyon &nbsp;</div>
                    <div className="text-white">2023</div>
                </div>
                <div className="flex flex-row justify-evenly items-center w-full text-center text-[18px] md:text-[25px]">
                    {columns.map((item) => (
                        <div className="bg-[#fb8315] w-[15vw] text-white h-[8vh] text-center flex items-center justify-center border border-white">{item}</div>
                    ))}
                </div>
                <div className="flex flex-col items-center justify-start w-full text-[14px] md:text-[20px]">
                    {classement.map((key, index) => {
                        const items = [index + 1, key.nom, key.premier, key.deuxieme, key.troisieme, key.premier + key.deuxieme + key.troisieme]
                        return (
                            <div className={"flex flex-row justify-evenly items-center w-full " + (index % 2 === 0 ? 'bg-[#808080]' : 'bg-[#696969]')} key={index}>
                                {items.map((item, i) => (
                                    <div className={"text-white h-[8vh] w-full text-center flex items-center justify-center border border-white"}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="w-screen h-[8vh] bg-softBlack" />

            <Border />

            <Footer />

            {isNavOpen && (
                <PortalDrawer
                    overlayColor="rgba(113, 113, 113, 0.3)"
                    placement="Left"
                    onOutsideClick={handleNavClick}
                >
                    <Nav onClose={handleNavClick} />
                </PortalDrawer>
            )}
        </div>
    );
}

export default Podium
