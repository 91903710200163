import SocialNetwork from "./socialNetwork";
import Logo from "./logo";

const Footer = () => {

  return (
    <div className="w-screen bg-gray-500 h-fit md:h-[1310px]">

      <Logo />

      <SocialNetwork />

    </div>
  );
};

export default Footer;