import { Link } from 'react-scroll';

const ItemsDrop = ({ text, color, link }) => {

  return (
    <Link to={link} smooth={true} duration={500} className="w-[30%] h-[35vh] flex flex-col items-center justify-start cursor-pointer">
      <div className={"leading-[30px] md:leading-[40px]  lg:leading-[50px] tracking-[3px] text-[20px] md:text-xl lg:text-2xl text-center flex items-center justify-center w-[90%] h-[60%]  text-" + color}>
        {text}
      </div>
      <div className="w-[90%] h-[40%] flex items-center justify-center">
        <img
          className="rounded-2xxs w-[40px] h-[40px] animate-bounce"
          src="../arrow_down.svg"
        />
      </div>
    </Link>
  );
};

export default ItemsDrop;

