import { Link } from "react-router-dom";

const Items = (props) => {

        return (
                <Link className="relative w-[200px] [text-decoration:none] h-[130px] flex flex-col items-start justify-start gap-[2px] cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110"
                        to={props.link}>

                        <div className={"rounded-xxl w-[80px] h-5 box-border flex flex-col item-center justify-start px-2 " + props.bg + " " + props.text}>
                                {props.title1}
                        </div>

                        <div className="text-5xs leading-[30px] h-[60px] text-white">
                                {props.title2}
                        </div>

                        <div className="text-13xs leading-[20px] text-white">
                                {props.description}
                        </div>
                </Link>
        )
}

export default Items;