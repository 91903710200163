import { useState, useEffect } from 'react';
import TimerComponents from './timerComponents';

const Countdown = () => {

  const dateEvent = new Date("March 22, 2024 20:00:00");

  const calculateTimeLeft = () => {
    const time = dateEvent - Date.now();

    let timeLeft = {};

    if (time > 0) {
      timeLeft = {
        days: Math.floor(time / (1000 * 60 * 60 * 24)),
        hours: Math.floor((time / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((time / 1000 / 60) % 60),
        seconds: Math.floor((time / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="rounded-large h-12 md:h-20 lg:h-13 bg-white py-0 px-[30px] items-center flex flex-row justify-between uppercase w-[150%] lg:w-[100%] text-black font-mono">

      <img
        className="relative h-[90%]"
        alt=""
        src="../logo.svg"
      />

      <div className="flex flex-col w-[80%] md:flex-row">

        <div className="relative text-center text-20xs md:flex md:items-center md:text-13xs md:w-[30%] md:leading-[23px] lg:tracking-[1px">
          Jusqu'au Challenge 2024
        </div>

        <div className="relative md:w-[70%] flex flex-row justify-between mx-10">

          <TimerComponents timeLeft={timeLeft.days} item={"jours"} />

          <TimerComponents timeLeft={timeLeft.hours} item={"heures"} />

          <TimerComponents timeLeft={timeLeft.minutes} item={"min"} />

          <TimerComponents timeLeft={timeLeft.seconds} item={"sec"} />

        </div>
      </div>
    </div>
  );
};

export default Countdown;