const MenuSocial = ({ link, click }) => {
    return (
        <img
            className="w-[60px] h-[60px] md:w-[70px] md:h-[70px] lg:w-[45px] lg:h-[45px] cursor-pointer border-white border-[1px]"
            src={link}
            onClick={click}
        />
    );
};

export default MenuSocial;
