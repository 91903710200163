import React from 'react';

const Section = ({ title, text, imageRow, imageCol, color, height}) => {
    const bgHeight = height ;//"h-[100vh] lg:h-[107vh]"
    const roundedClasses = "rounded-tr-[100px] md:rounded-tr-3xxl";
    const flexClasses = "flex flex-row items-start justify-between";
    const fontClasses = "text-left font-menu-main1";
    const textBlockClasses = "text-[1rem] md:text-[20px] lg:text-[25px] leading-[20px] lg:leading-[35px] lg:h-[90%] inline-block m-[5%] place-self-center overflow-y-auto";
    const textColor = color || "text-gray-700";

    return (
        <section className={`relative bg-gray-300 ${bgHeight} ${flexClasses} ${fontClasses}`}>
            <div className={`flex flex-col lg:flex-row items-center justify-between ${roundedClasses} bg-white w-screen ${bgHeight}`}>
                <div className={`lg:h-[100%] w-[100%] lg:w-[50%] text-center flex flex-col items-start justify-start font-source-sans-pro ${textColor}`}>
                    <div className="w-full uppercase lg:h-[10%] items-center text-2xl lg:text-3xl flex justify-center">
                        {title}
                    </div>
                    <div className={textBlockClasses}>
                        {text}
                    </div>
                </div>
                <img className="w-[100%] lg:w-[50%] justify-center h-[50vh] lg:h-[100%] object-cover object-center hidden lg:block" src={imageRow} alt="Section background" />
                <img className="w-[100%] lg:w-[50%] flex justify-center h-[50vh] lg:h-[100%] object-cover object-center lg:hidden" src={imageCol} alt="Section background" />
            </div>
        </section>
    );
};

export default Section;
