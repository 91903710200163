import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import DevenirPartenaire from "./pages/devenir-partenaire";
import Boutique from "./pages/boutique";
import Evenement from "./pages/evenement";
import Engagements from "./pages/engagements";
import Participer from "./pages/participer";
import Accueil from "./pages/accueil";
import Partenaires from "./pages/partenaires";
import Rediffusion from "./pages/rediffusion";
import Podium from "./pages/podium";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/devenirpartenaire":
        title = "";
        metaDescription = "";
        break;
      case "/boutique":
        title = "";
        metaDescription = "";
        break;
      case "/evenement":
        title = "";
        metaDescription = "";
        break;
      case "/engagements":
        title = "";
        metaDescription = "";
        break;
      case "/participer":
        title = "";
        metaDescription = "";
        break;
      case "/partenaires":
        title = "";
        metaDescription = "";
        break;
      case "/accueil":
        title = "";
        metaDescription = "";
        break;
      case "/rediffusion":
        title = "";
        metaDescription = "";
        break;
      case "/podium":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Accueil />} />

      <Route path="/devenirpartenaire" element={<DevenirPartenaire />} />

      <Route path="/boutique" element={<Boutique />} />

      <Route path="/evenement" element={<Evenement />} />

      <Route path="/engagements" element={<Engagements />} />

      <Route path="/participer" element={<Participer />} />

      <Route path="/accueil" element={<Accueil />} />

      <Route path="/partenaires" element={<Partenaires />} />

      <Route path="/rediffusion" element={<Rediffusion />} />

      <Route path="/podium" element={<Podium />} />

    </Routes>
  );
}
export default App;
