import Head from "../components/head";
import { useCallback, useState } from "react";
import Footer from "../components/footer";
import PortalDrawer from "../components/portal-drawer";
import Nav from "../components/nav";

const Rediffusion = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const handleNavClick = useCallback(() => {
    setNavOpen(!isNavOpen);
  }, [isNavOpen]);

  return (
    <div className="bg-softBlack w-screen flex flex-col items-start justify-start relative">
      <div className="h-[50vh] w-screen">
        <Head nav={handleNavClick} name="Rediffusion" />
      </div>

      <iframe className="w-screen h-screen" src="https://www.youtube.com/embed/sNgQEBDQL3g" title="Challenge 2023 - Show Pompom" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

      <Footer />
      {isNavOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={handleNavClick}
        >
          <Nav onClose={handleNavClick} />
        </PortalDrawer>
      )}
    </div>
  );
}

export default Rediffusion;