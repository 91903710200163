const TextPlaceHolder = ({ text }) => {
    return (
        <div className="w-screen h-screen flex items-center justify-center bg-softBlack">
            <div className="text-white font-menu-main1 text-center tracking-[1px] leading-[30px] md:leading-[50px] lg:leading-[60px] text-[15px] md:text-[25px] lg:text-[30px] font-medium w-[80%] max-h-[80%] justify-center items-center">
                {text}
            </div>
        </div>
    );
};

export default TextPlaceHolder;
