import Head from "./head";
import ItemsDrop from "./itemsDrop";

const AugmentedHead = ({ openNav, themeColor, textColor, mainText, mainImage, navName, items }) => {
    return (
        <>
            <div className="relative w-screen h-[25vh] md:h-[60vh] bg-softBlack">
                <Head nav={openNav} name={navName} />
            </div>

            <div className={"relative w-screen md:h-[50vh] flex items-center justify-center bg-[#172554] text-center text-[60px] md:text-[125px] lg:text-[150px] text-[#ea580c] font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"}>
                {mainText}
            </div>

            <div className={"flex flex-col justify-start items-center bg-[#172554] rounded-bl-[150px] md:rounded-bl-3xxl"}>

                <img className="h-[50vh] w-screen object-cover" src={mainImage} />

                <div className="w-screen h-[50vh] flex flex-row justify-evenly items-center">

                    {items.map(({text, link}) => (
                        <ItemsDrop text={text} color={textColor} link={link} />
                    ))}

                </div>

            </div>
        </>
    );
};

export default AugmentedHead;

//text-" + textColor +