const TimerComponents = ({ timeLeft, item }) => {

    return (
        <div className="relative w-[12.5%] flex flex-col items-center justify-center md:gap-[8px] lg:gap-[10px]">
            <b className="lg:tracking-[1.5px] text-15xs md:text-10xs">
                {timeLeft}
            </b>
            <div className="lg:tracking-[1px] text-20xs md:text-15xs">
                {item}
            </div>
        </div>
    );
};

export default TimerComponents;