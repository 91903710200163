import { useState, useCallback } from "react";
import Nav from "../components/nav";
import PortalDrawer from "../components/portal-drawer";
import Head from "../components/head";
import Footer from "../components/footer";
import SellItems from "../components/sellItems";
import Border from "../components/border";
import TextPlaceHolder from "../components/textPlaceHolder";

const Boutique = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const handleNavClick = useCallback(() => {
    setNavOpen(!isNavOpen);
  }, [isNavOpen]);

  return (
    <div className="bg-softBlack w-screen flex flex-col items-start justify-start">
      <Head nav={handleNavClick} name="Boutique" />
      <img className="object-cover w-screen h-screen" src="../escalade.png" />
      <Border />
      <TextPlaceHolder
        text="Cette page est faite pour présenter les différents articles que vous pouvez acheter au moment de votre inscription ou sur place. Cependant ils ne sont pas disponibles en ventes directement sur ce site."
      />
      <Border />
      <SellItems
        article="Pull"
        prix="30€"
        text="Le pull est un excellent moyen de montrer votre soutien à notre association et à la communauté sportive de Centrale Lyon. Optez pour le pull pour rester au chaud tout en ayant du style !"
        img="../pull.png"
        size="80%"
      />
      <Border />
      <SellItems
        article="Gourde"
        prix="4€"
        text="La gourde Challenge est la solution parfaite pour les étudiants qui cherchent à rester hydratés tout en étant respectueux de l'environnement. Optez pour la gourde Challenge pour être prêt à relever tous les défis tout en ayant un impact positif sur la planète !"
        img="../gourde.png"
        size="50%"
      />
      <Border />
      <Footer />
      {isNavOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={handleNavClick}
        >
          <Nav onClose={handleNavClick} />
        </PortalDrawer>
      )}
    </div>
  );
};


export default Boutique;
